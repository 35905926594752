.progress-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-width: 160px;
  border: 1px solid #c2c2c2;
  border-top: none;
}

.progress-section h5 {
  white-space: nowrap;
  overflow: visible;
  text-align: center;
}

.progress-percent {
  width: 100%;
  height: 20%;
  border-bottom: 1px solid #c2c2c2;
}

.progressbody-wrapper {
  position: relative;
  overflow: visible; /* Ensure children like the image are not clipped */
}

.progressbody {
  display: flex;
  /* overflow: visible; */
  /* overflow-x: scroll; */
  scrollbar-width: thin;
  position: relative;
  z-index: 1;
}

.progressbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* margin: 20px; */
  width: 100%;
}

.progressbar::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  background-color: #dcdcdc;
}

.progress-step {
  width: 1.1875rem;
  height: 1.1875rem;
  background-color: white;
  border: 2px solid #dcdcdc;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.progress-step-stage-1 {
  width: 1.1875rem;
  height: 1.1875rem;
  background-color: #dcdcdc;

  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: background-color 0.3s;
}

.progress-step-stage-2 {
  width: 1.1875rem;
  height: 1.1875rem;
  background-color: #dcdcdc;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: background-color 0.3s;
}

.progress-step-stage-3 {
  width: 1.1875rem;
  height: 1.1875rem;
  background-color: #dcdcdc;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: background-color 0.3s;
}

.progress-step-inner {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background-color: #dcdcdc;
}

.progress-step-inner-stage-1 {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.progress-step-inner-stage-2 {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.progress-step-inner-stage-3 {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.progress-percent {
  height: 50px;
  position: sticky;
  padding: 10px;
  background-color: #eef6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  /* border: 1px solid #c2c2c2; */
}

.loading-container {
  width: 100%;
  height: 20px;
  background-color: #e9e9e9;
  border-radius: 10px;
  position: relative;
}

.loading-bar {
  height: 100%;
  background: rgb(203, 227, 246);
  background: linear-gradient(
    90deg,
    rgba(203, 227, 246, 1) 0%,
    rgba(106, 186, 250, 1) 50%,
    rgba(32, 154, 252, 1) 100%
  );
  position: absolute;
  border-radius: 10px;
}

.percentage {
  position: absolute;
  top: 50%;
  /* right: -2px; */
  transform: translateY(-50%);
  padding: 5px 10px;
  font-size: 13px;
  width: 75px;
  height: 35px;
  color: #fff;
  background-color: #209afc;
  border-radius: 50% / 100% ;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.percentage::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Set background to transparent */
  border-radius: 50% / 100% ;
  z-index: 20; /* Adjust if needed */
  box-shadow: 0 0 0 3px rgb(255, 255, 255);
}
